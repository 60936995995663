@charset 'UTF-8';
@import "/Users/huytran/Websites/vhost/morijuken2021/src/scss/utility/_media-queries.scss";
@import "/Users/huytran/Websites/vhost/morijuken2021/src/scss/utility/_mixin.scss";


.mod-banner01 {
  @include SP {
    padding: 38px 0;
    a {
      &:not(:last-child) {
        margin-bottom: 17px;
      }
    }
  }
}

.mod-tabs {
  @include PC {
    margin-top: 187px;
  }
  @include SP {
    margin-top: 108px;
    padding-bottom: 0;
  }
  .nav-tabs {
    @include PC {
      margin-bottom: 80px;
    }
    .tab {
      &:not(.is-active) {
        a {
          border-color: #fff;
        }
      }
      @include PC {
        width: 18.18%;
        a {
          min-height: 68px;
        }
      }
      @include SP {
        a {
          min-height: 70px;
        }
      }
    }
  }
}

.office-list {
  @include PC {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    margin-bottom: 90px;
  }
  @include SP {
    margin-bottom: 42px;
  }
  .block {
    position: relative;
    @include PC {
      width: 47.27%;
    }
    @include SP {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
    .kind-title {
      .ttl-en {
        color: $red1;
        text-transform: uppercase;
        @include font(13,15,140);
        @include ffSpar;
        display: block;
      }
      .ttl-ja {
        @include font(20,24,0);
        margin-top: 21px;
        margin-bottom: 11px;
        display: block;
        @include PC {
          display: none;
        }
        @include SP {
          margin-top: 10px;
          margin-bottom: 3px;
          @include font(16,24,0);
        }
      }
    }
    .office-head {
      margin-bottom: 30px;
      @include SP {
        margin-bottom: 27px;
      }
      @include PC {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        @include align-items(center);
        a {
          overflow: visible;
        }
      }
      .sg-title {
        @include font-size(32);
        letter-spacing: 0.04em;
        line-height: 1.2;
        .ttl-ja {
          @include font(20,24,0);
          margin-left: 20px;
        }
        @include PC {
          width: calc(100% - 84px);
        }
        @include SP {
          @include font-size(28);
          .ttl-ja {
            display: none;
          }
        }
      }
    }
    .txt-view {
      width: 84px;
      span {
        &:before {
          content: '';
          position: absolute;
          bottom: -7px;
          height: 1px;
          width: 100%;
          background: #000;
        }
      }
    }
    > .hover-zoom01 {
      @include SP {
        margin-top: 18px;
        @include flexbox();
        @include justify-content(flex-end);
        overflow: visible;
      }
    }
    &-img {
      position: relative;
      z-index: 29;
      a {
        @include flexbox();
        @include flex-wrap(wrap);
      }
      .img {
        overflow: hidden;
      }
      .js-image {
        @include aspect-ratio(620, 347);
        @include SP {
          @include aspect-ratio(362, 203);
        }
      }
      .img01 {
        width: 100%;
      }
      .img02, .img03 {
        width: calc(50% - 5px);
        margin-top: 10px;
        &.is-full {
          width: 100%;
        }
        .js-image {
          @include aspect-ratio(305, 189);
          @include SP {
            @include aspect-ratio(178, 110);
          }
        }
      }
      .img02 {
        margin-right: 10px;
        &.is-full {
          margin-right: 0;
        }
      }
    }
    &.is_full {
      @include PC {
        width: 100%;
        a {
          display: block;
          clear: both;
        }
        .block-img {
          .img02, .img03 {
            margin-left: 0.55%;
            margin-right: 0;
            width: 23.3%;
            float: left;
            margin-top: 0;
            margin-bottom: 0.55%;
          }
          .img:nth-child(2n+1) {
            margin-right: 0;
          }
          .img01 {
            float: left;
            width: 52.3%;
            margin-right: 0;
          }
        }
      }
    }
  }
}
.event-list {
  .event-item {
    &:not(:last-child) {
      margin-bottom: 54px;
    }
  }
}

.top-bnr {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+10,ae2c30+10,ae2c30+100 */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top,  #ffffff 0%, #ffffff 14%, #ae2c30 14%, #ae2c30 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #ffffff 0%,#ffffff 14%,#ae2c30 14%,#ae2c30 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #ffffff 0%,#ffffff 14%,#ae2c30 14%,#ae2c30 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ae2c30',GradientType=0 ); /* IE6-9 */
    padding-bottom: 75px;
    margin: 100px 0 134px;
    @include SP {
      padding-bottom: 40px;
      margin: 70px 0;
    }
    p {
      &:not(:last-child) {
        margin-bottom: 15px;
        @include SP {
          margin-bottom: 20px;
        }
      }
    }
}
// top-bnr

.top-about {
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 135px;
  background-color: #fff;
  margin-top: 105px;
  @media (max-width: 1100px) {
    padding-top: 90px;
  }
  @include SP {
    padding-top: 76px;
    margin-top: 100px;
  }
  .cmn-title01 {
    max-width: 880px;
    margin: 0 auto;
    background: #fff;
    padding: 75px 0 57px;
    @include SP {
      padding: 50px 0 30px;
    }
  }
  .list-about {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    li {
      width: 23.636%;
      @include SP {
        width: 48%;
        margin-top: 20px;
        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
      }
      a {
        display: block;
        position: relative;
      }
      figure {
        @include aspect-ratio(260, 400);
        background-position: center;
      }
      .desc {
        position: absolute;
        width: 92%;
        background: #fff;
        z-index: 1;
        bottom: 0;
        right: 0;
        text-align: center;
        padding: 25px 10px 10px;
        @include SP {
          padding: 16px 10px 6px;
        }
        .txt-view {
          span {
            &:before {
              content: '';
              position: absolute;
              bottom: -10px;
              height: 1px;
              width: 100%;
              background: #000;
            }
          }
        }
        .sub-ttl {
          @include font(18,31,100,500);
          @media (max-width: 900px) {
            @include font(15,22,100,500);
          }
          @include SP {
            @include font(16,20,100,500);
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
          }
        }
      }
      .ttl {
        @include ffSpar;
        @include font(22,26,140,500);
        writing-mode: vertical-rl;
        position: absolute;
        top: 25px;
        left: 20px;
        z-index: 1;
        color: $red1;
        @include SP {
          @include font(20,23,140,500);
          top: 13px;
          left: 10px;
        }
      }
    }
  }
}

// top-about


.bnr-instagram {
  margin-top: -110px;
  @include SP {
    margin-top: -131px;
  }
  .cmn-title01 {
    margin-bottom: 24px;
    @include SP {
      margin-bottom: 45px;
    }
    .ttl-en {
      @include font(24,29,100,300);
      padding-top: 57px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        display: block;
        background: url($img_url + "img/common/icon/ico_instagram.svg") no-repeat center/100% 100%;
        top: 0;
        left: calc(50% - 15px);
      }
    }
  }
}

.request-top {
  .request-head {
    @include PC {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+10,ae2c30+10,ae2c30+100 */
      background: #ffffff; /* Old browsers */
      background: -moz-linear-gradient(top,  #ffffff 0%, #ffffff 16%, #ae2c30 16%, #ae2c30 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  #ffffff 0%,#ffffff 16%,#ae2c30 16%,#ae2c30 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  #ffffff 0%,#ffffff 16%,#ae2c30 16%,#ae2c30 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ae2c30',GradientType=0 ); /* IE6-9 */
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(space-between);
    }
    @include SP {
      background: #ae2c30;
    }
    > .item {
      padding: 0 12px 31px;
      a {
        color: #fff;
        display: block;
        @include HOVER {
          &:hover {
            opacity: 0.5;
            transition:.3s;
          }
        }
      }
      @include PC {
        text-align: center;
        width: calc(100%/3);
      }
      @include SP {
        padding: 30px 12px 25px;
      }
      &:not(:last-child) {
        @include PC {
          border-right: 1px solid #fff;
        }
        @include SP {
          border-bottom: 1px solid #fff;
        }
      }
      figure {
        @include PC {
          margin-bottom: 32px;
        }
      }
      .ttl {
        @include PC {
          @include font(20,32,40,700);
          margin-bottom: 14px;
        }
      }
      @include SP {
        .inner {
          @include flexbox();
          @include flex-wrap(wrap);
          @include justify-content(space-between);
          @include align-items(center);
          margin-bottom: 15px;
          figure {
            width: 46%;
          }
          .ttl {
            width: 54%;
            @include font(18,26,40,700);
            padding-left: 15px;
          }
        }
      }
    }
  }

  .important {
    background: #EEF0F1;
    padding: 100px 0;
    @include SP {
      padding: 80px 0 90px;
    }
    .ttl {
      text-align: center;
      @include font(34,58,100,500);
      margin-bottom: 52px;
      @include SP {
        @include font(26,38,100,500);
        margin-bottom: 44px;
      }
    }
    .about-list {
      @include flexbox();
      @include flex-wrap(wrap);
      margin-top: -30px;
      @include SP {
        margin-top: -10px;
        @include justify-content(space-between);
      }
      li {
        @include PC {
          width: 31.45%;
          margin-right: 2.825%;
          margin-top: 30px;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        @include SP {
          width: 48.5%;
          margin-top: 10px;
        }
        a {
          display: block;
          position: relative;
          figure {
            @include aspect-ratio(346, 346);
            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(54,56,60,0.6);
            }
          }
          &.dis-link {
            opacity: 0.5;
            pointer-events: none;
          }
          .desc {
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include flexbox();
            @include flex-wrap(wrap);
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(column);
            .sub-ttl {
              @include font(24,41,140,500);
              text-shadow: 0px 0px 10px #1F1E1E;
              margin-bottom: 10px;
              @include SP {
                @include font(18,26,140,500);
                text-align: center;
                margin-bottom: 5px;
              }
            }
            .txt-view {
              span {
                &:before {
                  content: '';
                  position: absolute;
                  bottom: -10px;
                  height: 1px;
                  width: 100%;
                  background: #fff;
                }
                &:after {
                  background: url($img_url + "img/common/icon/ico_arrow03.svg") no-repeat center/100% 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
// request-top

.new-fmzine {
  margin-bottom: 65px;
  @include SP {
    margin-bottom: 40px
  }
  .fmzine-item {
    &:not(:last-child) {
      @include PC {
        margin-bottom: 40px;
      }
      @include SP {
        margin-bottom: 25px;
      }
    }
    .js-image {
      @include PC {
        @include aspect-ratio(160, 115);
      }
      @include SP {
        @include aspect-ratio(144, 104);
      }
    }
    .ttl {
        @include font(16,25,0,700);
        margin-bottom: 10px;
        @include multiLineEllipsis(2);
    }
    .tags-list {
      .tag-item {
        @include PC {
          @include font-size(10);
        }
      }
    }
    a {
      @include flexbox;
      @include flex-wrap(wrap);
      .date {
        @include font(8,8,0,500);
        color: #777;
        margin-top: 10px;
      }
      .item-info {
        @include PC {
          width: 67%;
        }
        @include SP {
          width: 58.9%;
        }
        order: 2;
        padding: 0 0 0 25px;
        @include SP {
          padding: 0 0 0 20px;
        }
        .txt-line {
          @include font(12,12,0,500);
          margin-bottom: 10px;
          @include SP {
            margin-bottom: 13px;
          }
        }
      }
      .item-img {
        @include PC {
          width: 33%;
        }
        @include SP {
          width: 41.1%;
        }
        order: 1;
      }
    }
  }
}
.magazine {
  @include PC {
    margin: 135px 0 160px;
  }
  @include SP {
    margin: 73px 0 100px;
  }
  .wcm {
    @include PC {
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(space-between);
    }
    > section {
      @include PC {
        width: 44%;
      }
      @include SP {
        &:not(:last-child) {
          margin-bottom: 100px;
        }
      }
      .row-btn {
        @include SP {
          text-align: center;
        }
      }
      .cmn-title01 {
        @include PC {
          text-align: left;
          margin-bottom: 57px;
        }
        @include SP {
          margin-bottom: 38px;
        }
      }
    }
  }
}


.cmn-title01 .ttl-en {
  @include PC {
    margin-bottom: 7px!important;
  }
}


/* Modify 11-06-2020 */
.top-bnr-list{
  overflow: hidden;
    background: #EEF0F1;
    padding: 22px 0 78px;
    @include SP {
        overflow-x: hidden;
        padding: 0 0 52px;
    }
    .slick-arrow {
      width: 32px;
      height: 32px;
      position: absolute;
      top: calc(50% - 16px);
      z-index: 1;
      cursor: pointer;
      @include SP {
        display: none!important;
      }
      &:hover {
        opacity: 0.5;
        transition: .3s;
      }
      &:after {
        content: '';
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background: url($img_url + 'img/common/icon/ico_arr.svg') no-repeat center center / 100% auto;
      }
      &.slick-prev {
        left: 63px;
        @include SP {
          left: 0;
        }
      }
      &.slick-next {
        right: 63px;
        @include SP {
          right: 0;
        }
        &:after {
          @include rotate(180);
        }
      }
    }
    &__content{
      @include SP {
        margin-bottom: 0!important;
      }
      &:not(.slick-initialized) {
        .banner-item:first-child {
          width: 536px;
        }
        .banner-item:not(:first-child) {
          display: none;
        }
      }
      .slick-list {
        overflow: visible;
        margin: 0 auto;
        * {
          line-height: 1;
          font-size: 0;
        }
        @include PC {
          width: 536px!important;
        }
        @include SP {
          width: 344px!important;
          @include SPS {
            width: 300px!important;
          }
        }
        .slick-slide {
            padding: 0 10px;
            @include SP {
              padding: 0 5px;
          }
        }
      }

      .slick-dots {
        position: absolute;
        bottom: -45px;
        @include SP {
          bottom: -30px;
        }
        li {
          margin: 0 4px;
          button {
            width: 8px;
            height: 8px;
            background: #A8A8A8;
            border: 2px solid #EEF0F1;
            border-radius: 50%;
          }
          &.slick-active {
            button {
              border-color: #1F1E1E;
              background: #1F1E1E;
            }
          }
        }
      }
    }
    .banner-item{
        a {
            display: block;
            position: relative;

            figure {
              @include aspect-ratio(536,240);
              background-position: center;
              background-size: 100% 100%;
              display: block;
              img {
                display: none;
              }
            }
            img{
                width: 100%;
            }
        }
    }
}

.header {
  &:not(.header-fixed) {
    position: absolute;
    .header-inner {
      color: #fff;
      a {
        color: #fff;
      }
    }
    .header-logo {
      .off {
        opacity: 0;
        display: none;
        visibility: hidden;
      }
    }
    .header-contact a:before {
      background-image: url($img_url + 'img/common/icon/ico_tel_white.svg');
    }
  }
  .button-menu {
    background: #FEFEFF;
    .text {
      color: $color_body;
    }
    > span {
      background: $color_body;
    }
  }
  .header-menu {
    @include PC {
      li {
        &:not(:last-child) {
          &:before {
            background: #fff;
          }
        }
      }
    }
  }
  &.is-active,
  &.header-fixed {
    .button-menu {
      background: $color_body;
      .text {
        color: #fff;
      }
      > span {
        background: #fff;
      }
    }
    .header-contact a,
    .header-menu a,
    .header-inner,
    .header-text {
      color: $color_body;
    }
    .header-contact a:before {
      background-image: url($img_url + 'img/common/icon/ico_tel.svg');
    }
    .header-logo {
      .on {
        display: none;
        opacity: 0;
        visibility: hidden;
      }
      .off {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
    @include PC {
      .header-menu {
        li {
          &:not(:last-child) {
            &:before {
              background: $color_body;
            }
          }
        }
      }
    }
  }
}

.main-top,
.c-contact,
.footer {
  position: relative;
  z-index: 1;
  background: #fff;
}

.c-ranking {
  position: relative;
  z-index: 1;
}

#chatplusview #eye_catcher {
  opacity: 0 !important;
  visibility: hidden !important;
  &.is-show {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

/* KEYVISUAL
-------------------------------------------------------------*/
@include keyframes(showZoom) {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.top-keyvisual {
  position: relative;
  overflow: hidden;
  height: 100vh;
  .slick-slide {
    overflow: hidden;
    > div {
      font-size: 0;
    }
    &:not(.slick-active) .slide-child {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    &:not(.slick-current) {
      opacity: 0;
    }
  }
  .slideshow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    &:not(.slick-initialized) .slide-child {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
  .slide-child {
    // height: 100vh;
    @include PC {
      @include aspect-ratio(2732, 1430);
    }
  }
  .slick-animated {
    .slide-child {
      -webkit-animation: showZoom 7s linear;
      animation: showZoom 7s linear;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both
    }
  }
  .slick-dots {
    position: absolute;
    z-index: 1;
    right: 30px;
    bottom: 19px;
    text-align: right;
    width: auto;
    li {
      @include flexbox();
      @include align-items(center);
      margin: 7px 0;
      width: 8px;
      height: 8px;
      button {
        border: none;
      }
      &:not(.slick-active) {
        button {
          width: 4px;
          height: 4px;
          background: rgba(#fff, .70);
          margin: 0 auto;
        }
      }
      &.slick-active {
        button {
          width: 8px;
          height: 8px;
          background: #fff;
        }
      }
    }
  }

  .keyvisual-inner {
    position: relative;
    z-index: 1;
    text-align: center;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    height: 100%;
    padding: 0 20px;
  }
  .keyvisual-title {
    margin: -156px 0 0 6px;
    .txt {
      text-align: center;
      color: #fff;
      @include font(16,27,100,700);
      text-shadow: 0px 0px 6px rgba(0,0,0,0.6);
      display: block;
      margin-top: 2px;
      @include SP {
        @include font(14,24,100,700);
        margin-top: 14px;
      }
    }
  }
  .keyvisual-image {
    position: absolute;
    z-index: 1;
    left: 10px;
    bottom: 22px;
    @include min-screen(1024px) {
      left: 45px;
    }
    .img02 {
      margin-top: 18px;
        .bnr2022 {
          margin-bottom: 6px;
          display: block;
          @include SP {
            margin-bottom: 5px;
          }
        }
    }
  }
  @include PC {
    min-height: 715px;
    .slideshow,
    .slide-child {
      min-height: 715px;
    }
  }
  @include SP {
    @include landscape() {
      min-height: 640px;
      .slideshow,
      .slide-child {
        min-height: 640px;
      }
    }
    .slide-child {
      @include aspect-ratio(750, 1280);
    }
    .keyvisual-title {
      margin: -100px 0 0 20px;
    }
    .keyvisual-image {
      @include flexbox();
      @include align-items(flex-end);
      width: 100%;
      left: 11px;
      bottom: 26px;
      .img01 {
        max-width: 160px;
        width: 42.8%;
        margin-right: 11px;
        @media (max-width: 320px) {
          max-width: 130px;
        }
      }
      .img02 {
        margin: 0 0 -2px;
        max-width: 168px;
        width: 50%;
        @media (max-width: 375px) {
          max-width: 150px;
        }
        @media (max-width: 320px) {
          max-width: 130px;
        }
      }
    }
    .slick-dots {
      right: 18px;
    }
  }
}
.keyvisual-banner {
  a {
    display: block;
  }
  @include PC {
    position: absolute;
    z-index: 1;
    left: 218px;
    bottom: 22px;
    max-width: 841px;
    width: 70%;
    @include flexbox();
    @include flex-wrap(wrap);
    @include min-screen(1024px) {
      left: 279px;
    }
    a {
      margin: 10px 10px 0 0;
      width: calc(33.33% - 10px);
    }
  }
  @include SP {
    background: $gray1;
    padding-top: 26px;
    padding-bottom: 30px;
    & + .block-top-infor {
      .top-links {
        padding-top: 17px;
      }
    }
    a {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    img {
      display: block;
      width: 100%;
    }
  }
}

/* NEWS
-------------------------------------------------------------*/
.top-news {
  overflow: hidden;
  background: #fff;
  position: relative;
  z-index: 1;
  .news-inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 14px 20px 13px;
    @include SP {
      padding: 16px 11px 12px 21px;
    }
  }
  .news-slider {
    &:not(.slick-initialized) {
      .item {
        display: none;
        &:first-child {
          display: block;
        }
      }
    }
    .item a {
      @include flexbox;
      width: 100%;
      position: relative;
      padding: 0 20px;
      &:before {
        position: absolute;
        content: "";
        right: 19px;
        top: 50%;
        width: 16px;
        height: 10px;
        background: url($img_url+'img/common/icon/ico_arrow01.svg') no-repeat 0 0/ 100% 100%;
        margin-top: -6px;
      }
      @include SP {
        display: block;
        padding-left: 0;
        &:before {
          right: 1px;
          margin-top: -8px;
        }
      }
    }
    .date {
      position: relative;
      @include ffSpar;
      @include font-size(10);
      @include PC {
        @include flex(1);
        width: 87px;
        margin-top: 5px;
        @include IE {
          margin-top: 3px;
          padding-top: 2px;
        }
        &:before {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          height: 16px;
          width: 1px;
          background: #BCBCBC;
        }
      }
    }
    .info {
      @include PC {
        @include flexbox;
        width: calc(100% - 87px);
        padding: 0 20px;
      }
      @include SP {
        margin-top: 3px;
      }
    }
    .ttl {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @include font-size(16);
      font-weight: bold;
      @include PC {
        width: 25%;
        padding-right: 10px;
      }
    }
    .txt {
      @include PC {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include font-size(13);
        width: 75%;
        padding: 3px 0 0 6px;
      }
      @include SP {
        display: none;
      }
    }
    .slick-slide {
      overflow: hidden;
      > div {
        font-size: 0;
      }
      &[aria-hidden] {
        //transition: 1s;
      }
    }
  }
}


/* INFOR
-------------------------------------------------------------*/
.block-top-infor {
  overflow: hidden;
  position: relative;
  @include PC {
    > * {
      position: relative;
      z-index: 2;
    }
    &:before {
      display: none;
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 100%;
      height: 0;
      background: url($img_url+'img/top/bg_concept.png') no-repeat 50% 0/ cover;
      padding-top: 71.15%;
    }
  }
}
.top-links {
  max-width: 1240px;
  padding: 0 20px 112px;
  margin: 0 auto;
  @include SP {
    position: relative;
    background: $gray1;
    padding: 47px 12px 67px 13px;
    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      width: 100%;
      height: 0;
      background: url($img_url+'img/top/bg_concept_sp.png') no-repeat 100% 0/ cover;
      padding-bottom: 22.66%;
    }
  }
  ul {
    @include PC {
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(center);
      margin: 0 -15px;
      @include max-screen(1100px) {
        margin: 0 -15px;
      }
    }
    li {
      @include PC {
        @include flexbox();
        @include flex-wrap(wrap);
        width: 33.33%;
        padding: 0 15px;
        @include max-screen(1100px) {
          padding: 0 10px;
        }
      }
      @include SP {
        margin-bottom: 21px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &.first {
        .link-inner {
          @include SP {
            padding-bottom: 27px;
          }
        }
        .icn {
          margin: 0 auto 9px;
          @include SP {
            max-width: 32px;
            img {
              margin-left: -6px;
            }
          }
        }
        .ttl {
          @include letter-spacing(40);
          @include SP {
            margin-bottom: 2px;
          }
        }
      }
      &.second {
        .icn {
          margin: 0 auto 2px;
          @include SP {
            max-width: 34px;
            margin: -3px auto 4px;
            img {
              margin-left: -6px;
            }
          }
        }
      }
      &.third {
        .icn {
          margin: 0 auto 4px;
          @include SP {
            margin-bottom: 0;
          }
        }
        .ttl {
          @include letter-spacing(-100);
          @include min-screen(1101px) {
            @include font-size(22);
          }
        }
        .box-btn {
          @include min-screen(981px) {
            width: 53%;
          }
        }
      }
    }
  }
  .link-title {
    text-align: center;
    @include ffSpar;
    @include font-size(13);
    @include letter-spacing(140);
    position: absolute;
    left: 0;
    right: 0;
    top: -11px;
    @include IE {
      top: -9px;
    }
  }
  .link-inner {
    position: relative;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    width: 100%;
    background: #fff;
    border: 3px solid #D8DBDD;
    padding: 42px 13px;
    @include max-screen(1024px) {
      padding-left: 10px;
    }
    @include SP {
      padding: 29px 20px 24px 10px;
    }
    @include IP5 {
      padding-right: 12px;
    }
  }
  .link-box {
    @include flexbox();
    width: 100%;
    @include max-screen(1000px) {
      @include justify-content(center);
    }
    @include PC {
      @include max-screen(980px) {
        display: block;
      }
    }
  }
  .box-text {
    text-align: center;
    @include flex(1);
    @include PC {
      @include max-screen(980px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .ttl {
      @include PC {
        @include font-size(24);
        @include max-screen(1100px) {
          @include font-size(20);
        }
      }
      @include SP {
        @include font-size(20);
      }
      @include ANDR {
        @include font-size(18);
      }
      @include IP5 {
        @include font-size(17);
      }
    }
    .txt {
      @include font-size(14);
      line-height: 1.4;
      @include max-screen(1100px) {
        @include font-size(13);
      }
      @include IP5 {
        @include font-size(12);
      }
    }
  }
  .box-btn {
    padding-left: 10px;
    @include PC {
      width: 54%;
      @include max-screen(980px) {
        width: 100%;
      }
    }
    @include SP {
      width: 57%;
    }
  }
  .cmn-button {
    display: block;
    max-width: 160px;
    text-align: left;
    @include font-size(14);
    letter-spacing: 0;
    padding: 12px 12px 12px 15px;
    margin-bottom: 14px;
    @include IE {
      padding-top: 13px;
      padding-bottom: 11px;
    }
    @include max-screen(980px) {
      max-width: 100%;
    }
    @include SP {
      padding-top: 9px;
      padding-bottom: 9px;
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .c-arr {
      padding-left: 0;
      &:before {
        width: 13px;
        height: 8px;
        margin-top: -5px;
      }
    }
  }
}
.top-concept {
  margin-bottom: 105px;
  @include SP {
    margin-bottom: 65px;
  }
  .box-pic {
    position: relative;
    overflow: hidden;
    @include PC {
      float: left;
      width: 241px;
      &.is-right {
        float: right;
      }
    }
    @include SP {
      margin-bottom: 41px;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .box-info {
    @include PC {
      text-align: center;
    }
    .thumb {
      transition: 0.3s;
    }
    @include PC {
      float: right;
      width: calc(100% - 482px);
      padding: 84px 20px 0;
    }
    @include SP {
      padding: 0 12px 0 13px;
    }
  }
  .cmn-title01 {
    margin-bottom: 40px;
    @include SP {
      margin-bottom: 19px;
      text-align: left;
    }
    .ttl-en {
      @include PC {
        padding-left: 6px;
        margin-bottom: 35px;
      }
      @include SP {
        margin-bottom: 18px;
      }
    }
    .ttl-ja {
      @include PC {
        @include font(28,52,200);
      }
      @include SP {
        @include font-size(26);
        @include letter-spacing(140);
        line-height: 1.85;
      }
      @include IP5 {
        @include font-size(23);
      }
    }
  }
  .txt-base {
    letter-spacing: 0;
    margin-bottom: 56px;
    @include SP {
      margin-bottom: 45px;
    }
  }
  .row-btn {
    @include SP {
      text-align: center;
    }
  }
}


/* ABOUT
-------------------------------------------------------------*/
.sec-top-about {
  overflow: hidden;
  margin: 85px 40px 84px;
  @include SP {
    margin: 65px 12px 100px;
  }
  .list {
    @include flexbox();
    @include flex-wrap(wrap);
    a {
      position: relative;
      display: block;
      text-align: center;
      width: 100%;
      padding: 0 0 26px;
      @include SP {
        padding-top: 15px;
      }
      @include HOVER {
        opacity: 1;
        &:hover {
          .item-img {
            @include transform(translateY(-5px));
          }
          .txt-view span:after {
            @include transform(translateX(5px));
          }
        }
      }
      .txt-view {
        span {
          &:before {
            content: '';
            position: absolute;
            bottom: -10px;
            height: 1px;
            width: 100%;
            background: $color_body;
          }
        }
      }
    }
    .ttl {
      color: $red1;
      @include font-size(18);
      @include letter-spacing(0);
      line-height: 1.44;
      margin-bottom: 13px;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
    }
    .txt {
      line-height: 1.75;
      margin-bottom: 10px;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
    }
    .item-img {
      @include flex(1);
      @include add_prefix(transition, 0.3s ease all);
      span {
        display: inline-block;
        @include SP {
          img {
            height: 48px;
            width: auto;
          }
        }
      }
    }
    li {
      padding: 0 10px;
      @include PC {
        width: calc(100%/6);
        &:not(:last-child) {
          border-right: 1px solid #D7DBDD;
        }
      }
      @include SP {
        width: calc(100%/2);
        border-top: 1px solid #D7DBDD;
        border-right: 1px solid #D7DBDD;
        &:nth-child(even) {
          border-right: none;
        }
        &:nth-child(2),
        &:nth-child(1) {
          border-top: none;
        }
      }
    }
    .item-img {
      height: 100px;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      @include SP {
        height: 85px;
      }
    }
    .numb {
      @include font(13,15);
      position: absolute;
      top: 0;
      left: 0;
    }
    @include max-screen(1024px) {
      margin-left: -10px;
      margin-right: -10px;
      li {
        padding-left: 10px;
        padding-right: 10px;
      }
      .ttl {
        @include font-size(15);
      }
      .txt {
        @include font-size(14);
      }
    }
  }
}


/* WORKS
-------------------------------------------------------------*/
.sec-top-works {
  overflow: hidden;
  margin-bottom: 142px;
  position: relative;

  .work-item-first {
    padding-top: 18px;
    .slide-item {
      a {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
      }
      .w-image {
        width: 60.6%;
        &:before {
          width: 80px;
          height: 80px;
        }
        .img {
          @include aspect-ratio(830, 500);
        }
      }
      .w-desc {
        width: 39.4%;
        @include PC {
          padding: 20px 65px;
          margin-top: 0;
          @media (max-width: 1100px) {
            padding: 20px;
          }
        }

        &__ttl {
          @include font(28,46,40);
          margin-bottom: 33px;
        }
        .tags-list {
          margin-top: -5px;
          .tag-item {
            margin-top: 5px;
            margin-right: 24px;
          }
        }
        .txt-view {
          @include PC {
            margin-top: 72px;
            @media (max-width: 1100px) {
              margin-top: 30px;
            }
          }
          span {
            @include font-size(10);
            &:before {
              content: '';
              position: absolute;
              bottom: -7px;
              height: 1px;
              width: 100%;
              background: #000;
            }
          }
        }
      }
    }
  }
  .sec-works-inner {
    padding-top: 115px;
    position: relative;
    @include PC {
      padding-bottom: 30px;
    }
    @include SP {
      padding-top: 70px;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      border-bottom: 225px solid #EEF0F1;
      border-left: 50vw solid transparent;
      border-right: 50vw solid transparent;
      @include SP {
        border-bottom-width: 65px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 225px;
      left: 0;
      height: calc(100% - 225px);
      background: #EEF0F1;
      width: 100%;
      display: block;
      @include SP {
        top: 65px;
        height: calc(100% - 65px);
      }
    }
    .tags-block {
      @include PC {
        padding: 50px 20px 58px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
      }
      @include SP {
        padding: 0 12px 50px;
      }
      .ttl {
        color: $red1;
        @include ffSpar;
        @include PC {
          @include font(18,22,140);
          width: 15.5%;
        }
        @include SP {
          border-bottom: 1px solid $gray4;
          text-align: center;
          @include font(16,19,140);
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
      }
      .tags-list {
        @include PC {
          width: 84.5%;
          margin-top: -10px;
          .tag-item {
            margin-top: 10px;
            margin-right: 43px;
          }
        }
        @include SP {
          margin-top: -5px;
          .tag-item {
            margin-right: 15px;
            margin-top: 5px;
          }
        }
        .tag-item {
          @include font-size(14);
        }
      }
    }
    > .wcm {
      position: relative;
      z-index: 2;
    }
  }
  .row-btn {
    text-align: center;
    margin-top: 69px;
    .cmn-button01 .c-line {
      em {
        display: block;
        font-style: normal;
        @include font(11,22,100);
      }
    }
  }
  .txt-view {
    span {
      &:before {
        content: '';
        position: absolute;
        bottom: -7px;
        height: 1px;
        width: 100%;
        background: #000;
      }
    }
  }
  @include PC {
    .cmn-slide__content {
      padding-top: 15px;
      max-width: 1100px;
      margin: 0 auto;
      overflow: visible;
      .slick-arrow {
        top: 180px!important;
        transform: none!important;
        z-index: 2;
        &.slick-next {
          right: -7px;
        }
        &.slick-prev {
          left: -7px;
          right: inherit;
        }
      }
      .slick-list {
        width: 100%;
        max-width: 100%;
        .slick-slide {
          // width: 279px!important;
        }
      }
      .work-item .w-desc {padding-bottom: 4px;}
      .txt-view {
        margin-top: 20px;
      }
      &:not(.slick-initialized) {
        @include flexbox();
        @include justify-content(center);
        margin: 0 -6px;
        .slide-item {
          display: none;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            display: block;
            width: 20%;
            padding: 0 6px;
          }
        }
      }
    }
  }
  @include SP {
    margin-bottom: 90px;
    .cmn-slide__content {
      padding-top: 20px;
      .txt-view {
        margin-top: 10px;
      }
    }
    .cmn-title01 {
      margin-bottom: 30px;
      .ttl-ja {
        @include letter-spacing(140);
      }
    }
    .slide-item {
      margin-bottom: 31px;
    }
    .work-item {
      .w-desc {
        padding: 16px 13px 4px;
      }
      .w-desc__ttl {
        margin-bottom: 9px;
      }
    }
    .row-btn {
      margin-top: 16px;
    }
  }
}


/* MOVIE
-------------------------------------------------------------*/
.sec-top-movie {
  overflow: hidden;
  background: #fff;
  .row-btn {
    text-align: center;
    margin-top: 50px;
  }
  .thumb {
    @include aspect-ratio(340, 189);
  }
  .movie_youtube {
    position: relative;
    &:before {
      padding-top: 55%;
      content: "";
      position: relative;
      display: block;
    }
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @include PC {
    margin: 138px 0 105px;
    .cmn-title01 {
      .ttl-en {
        margin-bottom: 36px;
      }
    }
    .cmn-slide {
      margin-top: -43px;
      &__content {
        padding-top: 89px;
        &:not(.slick-initialized) {
          @include flexbox();
          @include justify-content(center);
          margin: 0 -6px;
          .slide-item {
            display: none;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              display: block;
              width: 20%;
              padding: 0 6px;
            }
          }
        }
      }
      .slick-list {
        max-width: 1054px;
      }
      .slick-slide {
        @include PC {
          padding: 0 6px;
          width: 352px;
        }
      }
      .txt-base {
        margin-top: 20px;
      }
    }
  }
  @include SP {
    margin: 100px 0;
    .cmn-title01 {
      margin-bottom: 46px;
      .ttl-ja {
        @include letter-spacing(140);
      }
    }
    .cmn-slide {
      padding: 0 12px 0 13px;
    }
    .cmn-slide__content {
      display: block;
    }
    .slide-item {
      width: 100%;
      margin-bottom: 47px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .txt-base {
      margin-top: 16px;
    }
    .row-btn {
      margin-top: 51px;
    }
  }
}


/* INTERVIEW
-------------------------------------------------------------*/
.sec-top-interview {
  overflow: hidden;
  .row-btn {
    text-align: center;
    margin-top: 50px;
    @include PC {
      .cmn-button01 {
        width: auto;
        max-width: 100%;
        min-width: 280px;
      }
    }
  }
  @include PC {
    margin-bottom: 122px;
    .cmn-title01 {
      margin-bottom: 35px;
      .ttl-en {
        margin-bottom: 36px;
      }
    }
    .interview-list {
      @include flexbox();
      @include flex-wrap(wrap);
      margin: 0 -15px 60px;
      &.is-center {
        @include justify-content(center);
      }
      .interview-item {
        width: 33.33%;
        padding: 0 15px;
      }
      a {
        max-width: 346px;
      }
    }
  }
  @include SP {
    margin-bottom: 80px;
    .cmn-title01 {
      margin-bottom: 48px;
      .ttl-ja {
        @include letter-spacing(140);
        @include IP5 {
          @include font-size(24);
        }
      }
    }
    .interview-list {
      .interview-item {
        margin-bottom: 56px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}


/* BANNER
-------------------------------------------------------------*/
.top-banner {
  margin-bottom: 142px;
  overflow: hidden;
  @include SP {
    margin-bottom: 99px;
  }
  .wcm {
    position: relative;
    padding-bottom: 40px;
    @include SP {
      padding-bottom: 13px;
    }
    &:after,
    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      background: $red1;
      height: calc(100% - 104px);
      width: 130px;
      @include SP {
        height: calc(100% - 63px);
        width: 64%;
      }
    }
    &:before {
      right: auto;
      left: 100%;
      width: 200%;
    }
  }
  .thumb {
    position: relative;
    z-index: 2;
  }
}


/* EVENTS
-------------------------------------------------------------*/
.sec-top-event {
  margin-bottom: 100px;
  @include SP {
    margin-bottom: 82px;
  }
  .cmn-title01 {
    margin-bottom: 65px;
    @include SP {
      margin-bottom: 52px;
    }
    .ttl-en {
      @include PC {
        margin-bottom: 36px;
      }
    }
    .ttl-ja {
      @include SP {
        @include letter-spacing(140);
      }
      @include ANDR {
        @include font-size(26);
      }
      @include IP5 {
        @include font-size(24);
      }
    }
  }
  .row-btn {
    text-align: center;
    @include PC {
      margin-top: 11px;
      @include max-screen(1024px) {
        .c-arr {
          padding-left: 18px;
          padding-right: 18px;
        }
      }
    }
    @include SP {
      margin-top: 52px;
    }
  }
}

/* LAND
-------------------------------------------------------------*/
.top-land {
  margin-bottom: 122px;
  @include SP {
    margin-bottom: 67px;
  }
  .smilest-inner {
    @include flexbox();
    max-width: 880px;
    width: 100%;
    background: #AE2C30;
    margin: 0 auto 28px;
    @include SP {
      margin-bottom: 14px;
    }
  }
  .smilest-left {
    position: relative;
    overflow: hidden;
    background: url($img_url + 'img/top/bg_smilest.jpg') no-repeat 100% 0/ cover;
    @include PC {
      width: 39.774%;
      @include flexbox();
      @include align-items(center);
      padding-top: 8px;
      .thumb {
        width: 73.2%;
      }
    }
    @include SP {
      width: 43%;
      background-image: url($img_url + 'img/top/bg_smilest_sp.jpg');
      background-position: 100% 100%;
      padding-top: 11px;
      .thumb {
        width: 95.2%;
      }
    }
  }
  .smilest-right {
    width: 60.2%;
    padding: 21px 69px 20px 10px;
    position: relative;
    text-align: center;
    @include SP {
      width: 57%;
      padding: 14px 20px 16px 17px;
      text-align: left;
    }
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      right: 20px;
      width: 26px;
      height: 26px;
      background: url($img_url + 'img/top/ico_arrow02.svg') no-repeat 0 0/ 100% 100%;
      margin-top: -15px;
      @include SP {
        width: 20px;
        height: 20px;
        right: 13px;
        margin-top: -10px;
      }
    }
  }
  .land-inner {
    @include flexbox();
    max-width: 880px;
    margin: 0 auto;
    width: 100%;
    background: #5E88A5;
    color: #fff;
  }
  .land-left {
    position: relative;
    width: 44.8%;
    overflow: hidden;
    @include flexbox();
    @include justify-content(flex-end);
    @include align-items(flex-end);
    @include SP {
      width: 30%;
    }
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 74%;
      max-width: 292px;
      height: 100%;
      background: url($img_url + 'img/top/img_land.jpg') no-repeat 0 0/ cover;
      @include SP {
        max-width: 100%;
        width: 85%;
        background-image: url($img_url + 'img/top/img_land_sp.jpg');
      }
    }
    .thumb {
      position: relative;
      z-index: 2;
      margin-bottom: -2px;
      @include SP {
        margin-bottom: 0;
        max-width: 82px;
        right: 5px;
      }
    }
  }
  .land-right {
    width: 55.2%;
    padding: 18px 58px 24px 13px;
    position: relative;
    @include SP {
      width: 70%;
      padding: 15px 36px 19px 3px;
    }
    &:before {
      position: absolute;
      content: "";
      top: 50%;
      right: 20px;
      width: 26px;
      height: 26px;
      background: url($img_url + 'img/top/ico_arrow01.svg') no-repeat 0 0/ 100% 100%;
      margin-top: -17px;
      @include SP {
        width: 20px;
        height: 20px;
        right: 13px;
        margin-top: -10px;
      }
    }
    .txt {
      @include font-size(13);
      line-height: 1.7;
      margin-top: 18px;
      @include SP {
        margin-top: 13px;
      }
    }
  }
}


/* FACE MAGAZINE
-------------------------------------------------------------*/
.sec-top-facemagazine {
  overflow: hidden;
  margin-top: 140px;
  @include SP {
    margin-top: 80px;
  }
  .wcm {
    @include PC {
      padding-top: 140px;
      padding-bottom: 300px;
    }
    @include SP {
      padding-top: 80px;
      padding-bottom: 230px;
    }

    position: relative;
    > * {
      position: relative;
      z-index: 2;
    }
    &:after,
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: calc(50% + 144px);
      height: 100%;
      background: $gray1;
      @include SP {
        width: calc(50% + 82px);
      }
    }
    &:after {
      left: auto;
      right: 100%;
      width: 100%;
    }
  }
  .title-big {
    color: $red1;
    @include ffSpar;
    @include font(36,42,100);
    margin-bottom: 63px;
    @include SP {
      @include font-size(28);
      margin-bottom: 46px;
    }
  }
  .list {
    @include PC {
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(flex-end);
      margin-top: -90px;
      .item {
        margin-top: 90px;
        width: 31.45%;
        margin-right: 2.825%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
    @include SP {
      .item {
        margin-bottom: 56px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .thumb {
      @include aspect-ratio(460, 331);
    }
    .txt-line {
      margin-bottom: 14px;
      font-weight: 500;
    }
    .item-ttl {
      font-weight: bold;
      margin-bottom: 15px;
      @include multiLineEllipsis(2);
    }
    .tags-list {
      .tag-item {
        @include PC {
          @include font-size(10);
        }
      }
    }
    .date {
      @include ffSpar;
      font-weight: 400;
      @include font-size(8);
      margin-bottom: 34px;
      margin-top: 10px;
      color: #777;
      @include SP {
        margin-bottom: 24px;
      }
    }
  }
  .row-btn {
    margin: 71px 0 118px;
  }
}
.top-banner01 {
  overflow: hidden;
  text-align: center;
  .box {
    color: #fff;
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      right: 20px;
      width: 17px;
      height: 10px;
      background: url($img_url+'img/common/icon/ico_arrow03.svg') no-repeat 0 0/ 100% 100%;
    }
  }
  .ttl {
    @include font-size(24);
    @include letter-spacing(100);
  }
  .txt {
    @include font-size(15);
    line-height: 1.87;
    // max-width: 384px;
    margin: 6px auto 0;
  }
  @include PC {
    @include flexbox();
    @include justify-content(space-between);
    .box {
      width: 49.1%;
      padding: 37px 40px 34px;
    }
  }
  @include SP {
    margin-bottom: 50px;
    .box {
      padding: 21px 44px 22px;
      &:after {
        margin-top: -5px;
        @include ANDR {
          right: 12px;
        }
      }
      & + .box {
        margin-top: 12px;
      }
    }
    .ttl {
      @include font-size(22);
    }
    .txt {
      line-height: 1.75;
      max-width: 240px;
    }
  }
}

